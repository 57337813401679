import * as React from "react"
import { Link } from "gatsby"
import logo from '../images/logo.png';
import Layout from '../components/Layout'
import './styles.scss';

const imgStyles = {
  maxWidth: 500,
  width: '100%',
  margin: '3rem 0'
}

const library = [
  {
    title: 'Crushing It!',
    url: 'https://www.audible.com/pd/Crushing-It-Audiobook/B0783R9DPV',
    description: 'How Great Entrepreneurs Build Their Business and Influence-and How You Can, Too',
    author: 'Gary Vaynerchuk',
  },
  {
    title: 'Start with Why',
    url: 'https://www.audible.com/pd/Start-with-Why-Audiobook/B074VDVHZ5',
    description: 'How Great Leaders Inspire Everyone to Take Action',
    author: 'Simon Sinek',
  },
  {
    title: 'The Compound Effect',
    url: 'https://www.audible.com/pd/The-Compound-Effect-Audiobook/1982648066',
    description: 'Multiply Your Success One Simple Step at a Time',
    author: 'Darren Hardy',
  },
  {
    title: 'Turn the Ship Around!',
    url: 'https://www.audible.com/pd/Turn-the-Ship-Around-Audiobook/0593415256',
    description: 'A True Story of Turning Followers Into LeadersThe Compound Effect',
    author: 'L. David Marquet',
  }
]
// take everything you learned and make it your own
const IndexPage = () => {
  return (
    <Layout className="home">
      <div className="text-align-center">
        <img
          alt="Mister Jey logo"
          src={logo}
          style={imgStyles}
        />
      </div>
      <div className="center">
        <p><strong>Father, content creator and geek.</strong></p>
        {/* <p>In a mission to improve the world we live in... one project at a time.</p> */}
      </div>

      {/* <section>
        <h1>What I currently 📚 or 🎧</h1>
        {library.map(x => {
          return (
            <article>
              <header>
                <h1><a href={x.url} target="_blank">{x.title}</a></h1>
              </header>
              <p>{x.description}</p>
              <p><small>By {x.author}</small></p>
            </article>
          )
        })}
      </section> */}
      <section>
        <h1>Current project: Reborn Again</h1> 
        <Link to="http://rebornagain.art">Click here to know more ➡️</Link>
      </section>
      
    </Layout>
  )
}

export default IndexPage
